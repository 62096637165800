import React from 'react';
import Flex from "../../common/Flex";
import {Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setLanguage, selectLang} from "../../../app/store/reducers/main/main";

const ChangeLanguage = () => {

    const lang = useSelector(selectLang)
    const dispatch = useDispatch()

    const changeLanguage = (lang:string) => {
        dispatch(setLanguage(lang))
    }

    return (
        <Flex className={'gap-3 cursor-pointer'} justifyContent={'between'}>
            <Col onClick={()=>changeLanguage('ru')} className={`${lang === 'ru' && 'text-primary fw-bolder'}`} cols={6}>Русский</Col>
            <Col onClick={()=>changeLanguage('uz')} className={`${lang === 'uz' && 'text-primary fw-bolder'}`} cols={6}>Узбекский</Col>
        </Flex>
    );
};

export default ChangeLanguage;
