import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";

import App from './App';
import Main from "./Main";
import store from "./app/store/store";
import './referal-main.css'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <Main>
            <App />
        </Main>
    </Provider>

);
