import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiCall} from "../../config/config";
import {IAgentRegister, IAgents, IAgentState, IOrganizationBoughtPackage, IPaginationOptions} from "./type";
import {IRoot} from "../types";
import toast from "react-hot-toast";

const initialState:IAgentState = {
    agents: [],
    agent: {},
    agentOrganizations: [],
    organizationBoughtPackage: [],
    error: null,
    status: '',
    filterOptions: {},
    count: 0,
    paginationOptions: {page: 1, limit: 10}
}

export const agentReducer = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        // common
        onRequest: (state) => {
            state.error = null
            state.status = ''
        },
        onFail: (state, {payload}) => {
            state.error = payload
        },

        //forms
         onRegisterAgentSuccess: (state) => {
             state.error = null;
             state.status = true
             toast.success('УСПЕШНО')
        },

        // get agent
        onGetAgentSuccess:(state, action:PayloadAction<IAgents>) => {
            state.agent = action.payload
            state.status = true
        },

        // get agents
        onGetAgentsSuccess: (state, action:PayloadAction<IAgents[]>) => {
            state.agents = action.payload
            state.error = ''
        },
        onGetAgentsCount: (state, action)=>{
            state.count = action.payload
        },

        // organization bought
        onGetOrganizationBoughtPackagesRequest: (state) => {
            state.organizationBoughtPackage = []
        },

         onGetOrganizationBoughtPackagesSuccess: (state,action:PayloadAction<IOrganizationBoughtPackage[]>) => {
            state.organizationBoughtPackage = action.payload
        },

        //filter
        changeFilterAgents: (state, action:PayloadAction<{limit?: number, page?: number, q?:string | null}>) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
            state.paginationOptions = {...state.paginationOptions, ...action.payload}
        }
    },

})


//selectors

export const selectAgents = (state:IRoot) => state.agent.agents
export const selectAgentError = (state:IRoot) => state.agent.error
export const selectAgentStatus = (state:IRoot) => state.agent.status
export const selectFilterOptions = (state:IRoot) => state.agent.filterOptions
export const selectPaginationOptions = (state:IRoot) => state.agent.paginationOptions
export const selectCount = (state:IRoot) => state.agent.count
export const selectAgentOrganizations = (state:IRoot) => state.agent.agentOrganizations
export const selectOrganizationBoughtPackage = (state:IRoot) => state.agent.organizationBoughtPackage

//agent
export const agentRegisterAsync = (data: IAgentRegister) => apiCall({
        url: 'api/agent/register',
        method: 'post',
        data,
        onRequest: agentReducer.actions.onRequest.type,
        onSuccess: agentReducer.actions.onRegisterAgentSuccess.type,
        onFail: agentReducer.actions.onFail.type
})

export const getAgentAsync = (id: string | number) => apiCall({
        url: 'api/agent/get/' + id,
        method: 'get',
        onRequest: agentReducer.actions.onRequest.type,
        onSuccess: agentReducer.actions.onGetAgentSuccess.type,
        onFail: agentReducer.actions.onFail.type
})


//agents
export const getAgentsAsync = (filters: object, pagination: IPaginationOptions) => {

    const {page, limit} = pagination
    const offset = (page - 1) * limit
    const params = {offset, limit, ...filters}

    return apiCall( {
        url: 'api/agent/get',
        method: 'get',
        params,
        onRequest: agentReducer.actions.onRequest.type,
        onSuccess: agentReducer.actions.onGetAgentsSuccess.type,
        onFail: agentReducer.actions.onFail.type
    })
}

export const getOrganizationBoughtPackagesAsync = (id:string | number) => apiCall({
    url: 'api/organization/bought-packages/'+id,
    method:'get',
    onRequest: agentReducer.actions.onGetOrganizationBoughtPackagesRequest.type,
    onSuccess: agentReducer.actions.onGetOrganizationBoughtPackagesSuccess.type,
    onFail: agentReducer.actions.onFail.type
})

export const getAgentsCountAsync = () => apiCall({
    url: 'api/agent/count',
    method:'get',
    onSuccess: agentReducer.actions.onGetAgentsCount.type,
})

export const {changeFilterAgents} = agentReducer.actions
export default agentReducer.reducer
