import React, {useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import {getItemFromStore} from './helpers/utils';
import {configReducer} from './reducers/configReducer';
import {settings} from "./config";
import useToggleStyle from './hooks/useToggleStyle';
import 'react-datepicker/dist/react-datepicker.css';
import is from 'is_js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {setDefaultLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';

setDefaultLocale(ru);

const Main = props => {
    const configState = {
        isFluid: getItemFromStore('isFluid', settings.isFluid),
        isRTL: getItemFromStore('isRTL', settings.isRTL),
        isDark: getItemFromStore('isDark', settings.isDark),
        navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
        isNavbarVerticalCollapsed: getItemFromStore(
            'isNavbarVerticalCollapsed',
            settings.isNavbarVerticalCollapsed
        ),
        navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
        currency: settings.currency,
        showBurgerMenu: settings.showBurgerMenu,
        showSettingPanel: false,
        navbarCollapsed: false
    };

    const [config, configDispatch] = useReducer(configReducer, configState);

    useToggleStyle(
        config.isRTL,
        config.isDark,
        configDispatch
    );

    const setConfig = (key, value) => {
        configDispatch({
            type: 'SET_CONFIG',
            payload: {
                key,
                value,
                setInStore: [
                    'isFluid',
                    'isRTL',
                    'isDark',
                    'navbarPosition',
                    'isNavbarVerticalCollapsed',
                    'navbarStyle'
                ].includes(key)
            }
        });
    };

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
    }, [HTMLClassList]);

    return (
        <AppContext.Provider value={{config, setConfig}}>
            {props.children}
        </AppContext.Provider>
    );
};

Main.propTypes = {children: PropTypes.node};

export default Main;
