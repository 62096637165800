import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IAccountRegister, IAuthState, IOrganizations, LoginAsync} from "./type";
import {LOGIN_REQUEST} from '../../../eventbus/authEvent'
import {apiCall} from "../../config/config";
import EventBus from "../../../eventbus/EventBus";
import toast from "react-hot-toast";
import {IRoot} from "../types";
import {IChangePassword} from "../../../../components/settings/type";

const initialState: IAuthState = {
    isAuthorized: false,
    getMe: null,
    error: null,
    info: {},
    status: '',
    organizations: []
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // common
        onFail: (state, action) => {

        },

        // login
        onLoginSuccess: (state) => {
            state.isAuthorized = true
        },
        onLoginFail: (state, action: PayloadAction) => {
            state.isAuthorized = false
            toast.error('неправильный логин или пароль')
        },

        // initialize
        onInitializeSuccess: (state,action) => {
            state.info = action.payload.agent
            state.isAuthorized = true
        },
        onInitializeFail: (state) => {
            state.isAuthorized = false
            state.getMe = false
        },

        // account forms
        onAccountRegisterSuccess: (state, action) => {
            state.error = null
            state.status = true
            toast.success('УСПЕШНО')
        },
        onAccountRegisterFail: (state, action)=> {
            state.error = true
            toast.error('БЕЗУСПЕШНО')
        },

        // account update
        onAccountUpdateSuccess: (state, action) => {
            state.error = null
            toast.success('УСПЕШНО')
        },
        onAccountUpdateFail: (state, action) => {
            toast.error('БЕЗУСПЕШНО')
            state.error = action.payload
        },

        // organization
        onLoadOrganization: (state, action:PayloadAction<IOrganizations[]>)=> {
            state.organizations = action.payload
        }
    }
})


// actions

export const selectAuthError = (state:IRoot) => state.auth.error
export const selectAuthStatus = (state:IRoot) => state.auth.status
export const selectIsAuthorized = (state: IRoot) => state.auth.isAuthorized
export const checkGetMe = (state: IRoot) => state.auth.getMe
export const selectAccount = (state:IRoot) => state.auth.info
export const selectOrganization = (state:IRoot) => state.auth.organizations

export const loginAsync = (data: LoginAsync) => {
    EventBus.dispatch(LOGIN_REQUEST, {data});

    return apiCall({
        url: 'login',
        method: 'post',
        data,
        onSuccess: authSlice.actions.onLoginSuccess.type,
        onFail: authSlice.actions.onLoginFail.type
    })
}

export const logoutAsync = () => apiCall({
    url: 'logout',
    method: 'get',
    onSuccess: '',
})

export const initializeAsync = () => {

    return apiCall({
        url: "api/account/get-me",
        method: 'get',
        onSuccess: authSlice.actions.onInitializeSuccess.type,
        onFail: authSlice.actions.onInitializeFail.type
    })
}

export const accountRegisterAsync = (data: IAccountRegister) => {

    return apiCall({
        url: 'api/account/register',
        method: 'post',
        data,
        onSuccess: authSlice.actions.onAccountRegisterSuccess.type,
        onFail: authSlice.actions.onAccountRegisterFail.type
    })
}


export const accountUpdateAsync = (data:IAccountRegister) => {
    return apiCall({
        url: 'api/account/update/' + data.agent_id,
        method: 'post',
        data,
        onSuccess: authSlice.actions.onAccountUpdateSuccess.type,
        onFail: authSlice.actions.onAccountUpdateFail.type
    })
}

export const loadOrganizationAccountAsync = (id:string | number) => apiCall({
    url: 'api/organization/get',
    method: 'get',
    onSuccess: authSlice.actions.onLoadOrganization.type,
    onFail: authSlice.actions.onFail.type

})

export const changeAccountPassword = (data:IChangePassword) => apiCall({
    url: 'api/account/change-password',
    method: 'post',
    data,
    onSuccess: authSlice.actions.onAccountUpdateSuccess.type,
    onFail: authSlice.actions.onAccountUpdateFail.type
})

export const {onInitializeFail} = authSlice.actions
export default authSlice.reducer
