import {FC} from 'react';
import {IFlex} from "./types";
import classNames from "classnames";

const Flex: FC<IFlex> = (props) => {
    const {
            justifyContent, alignItems, alignContent, inline, wrap, className,
            tag: Tag = 'div', children, breakpoint, direction, rest
    } = props
    return (
        <Tag className={classNames(
            {
                [`d-${breakpoint ? breakpoint + '-' : ''}flex`]: !inline,
                [`d-${breakpoint ? breakpoint + '-' : ''}inline-flex`]: inline,
                [`flex-${direction}`]: direction,
                [`justify-content-${justifyContent}`]: justifyContent,
                [`align-items-${alignItems}`]: alignItems,
                [`align-content-${alignContent}`]: alignContent,
                [`flex-${wrap}`]: wrap
            },
            className
        )}
             {...rest}
        >
            {children}
        </Tag>
    );
};

export default Flex;
