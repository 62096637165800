import React, {FC, useContext, useEffect, useState} from 'react';
import {Nav, Navbar} from "react-bootstrap";
import Logo from "../../Logo";
import NavbarTopDropdownMenus from "./NavbarTopDropdownMenus";
import NavTopRightSide from "./NavTopRightSide";
import AppContext from "../../../context/Context";
import classNames from "classnames";
import {topNavbarBreakpoint} from "../../../config";
import {useLocation} from "react-router-dom";

const NavbarTop: FC = () => {

    const {
        config: {showBurgerMenu, navbarPosition, navbarCollapsed},
        setConfig
    } = useContext<any>(AppContext);

    const mediaXl = window.matchMedia("(min-width: 991px)")

    const [showDropShadow, setShowDropShadow] = useState<boolean>(false);
    const [match, setMatch] = useState<boolean>(mediaXl.matches)

    const {pathname} = useLocation();

    mediaXl.addEventListener('change', (e:any) => {
        setMatch(e.matches)
    })

    const isChat = pathname.includes('chat');

    const handleBurgerMenu = () => {
        navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
        (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
        setConfig('showBurgerMenu', !showBurgerMenu);
    };

    const setDropShadow = () => {
        const el = document.documentElement;
        if (el.scrollTop > 0) {
            setShowDropShadow(true);
        } else {
            setShowDropShadow(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', setDropShadow);
        return () => window.removeEventListener('scroll', setDropShadow);
    }, []);

    return (
        <Navbar
            className={classNames('navbar-glass justify-content-start align-items-center  justify-content-md-between fs--1 navbar-top sticky-kit py-0', {
                // 'navbar-glass-shadow': showDropShadow
                'navbar-glass-shadow': showDropShadow && !isChat
            })}
            expand={
                navbarPosition === 'top' || navbarPosition === 'combo'
                    ? topNavbarBreakpoint
                    : true
            }
        >
            <Navbar.Toggle className={'toggle-icon-wrapper d-lg-none me-md-3 me-2'} as="div">
                <button className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                        onClick={handleBurgerMenu} id="burgerMenu">
                     <span className="navbar-toggle-icon">
                        <span className="toggle-line"/>
                     </span>
                </button>
            </Navbar.Toggle>

            <Logo navbarTop to={'/'} textClass={'fs-1'} at='navbar-top' width={40}/>

            <Navbar.Collapse
                style={{flex: '1 0 auto', overflow: match ? 'visible' : "auto"}}
                in={navbarCollapsed}
                className={classNames('scrollbar pb-3 pb-lg-0', {
                    "w-100": !match
                })}
            >
                <Nav navbar>
                    <NavbarTopDropdownMenus/>
                </Nav>
            </Navbar.Collapse>


            <NavTopRightSide/>
        </Navbar>
    );
};

export default NavbarTop;
