export const version = '3.8.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    navbarPosition: 'top',
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: '$',
    isNavbarVerticalCollapsed: false, // toggle vertical sidebar collapse
    navbarStyle: 'transparent'
};
const config = {version, navbarBreakPoint, topNavbarBreakpoint, settings};
export default config
