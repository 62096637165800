import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IMainState} from "./type";
import {IRoot} from "../types";


export const initialState:IMainState = {
    lang: localStorage.getItem('lang') || 'ru'
}

export const mainSlice =createSlice({
    name: 'main',
    initialState,
    reducers: {
        setLanguage: (state,action:PayloadAction<string>) => {
            localStorage.setItem('lang', action.payload)
            state.lang = action.payload
        }
    }
})

export const {setLanguage} = mainSlice.actions
export default mainSlice.reducer

// select


export const selectLang = (state:IRoot) => state.main.lang
