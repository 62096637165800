import  {FC, useRef} from 'react';
import {Col, Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import Flex from "../../common/Flex";
import {selectLang} from "../../../app/store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import { ImExit } from "react-icons/im";
import {useSelector} from "react-redux";

const Exit:FC = () => {

    const lang = useSelector(selectLang)
    const t = Translate

    let dropdown = useRef<HTMLLIElement>(null)
    let dropdownMenu = useRef<HTMLDivElement>(null)

    function closeDropdown() {
        dropdown.current?.classList.remove('show')
        dropdownMenu.current?.classList.remove('show')
    }

    return (
        <Dropdown ref={dropdown} navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="p-1 nav-link text-center"
            >
                <ImExit className={'fs-2 text-danger'}/>
            </Dropdown.Toggle>

            <Dropdown.Menu ref={dropdownMenu} className="dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <div className={'text-center fw-bold text-danger'}>
                        {t(lang, 'navbar.top.dropdown_item.logout')}
                    </div>
                    <Flex>
                        <Col as={Link} to={'/authentication/logout'} className={'text-center text-decoration-none'}>
                            {t(lang, 'common.select_yes_or_no.option.yes')}
                        </Col>
                        <Col onClick={closeDropdown} className={'text-center cursor-pointer text-secondary'}>
                            {t(lang, 'common.select_yes_or_no.option.no')}
                        </Col>
                    </Flex>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Exit;
