import {combineReducers} from "@reduxjs/toolkit";
import {IRoot} from "./types";

import auth from './auth/auth'
import main from "./main/main";
import agentReducer from "./agent/agent";

export const root = combineReducers<IRoot>({
   auth,
   main,
   agent: agentReducer
})

export type RootState = ReturnType<typeof root>
