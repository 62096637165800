import {FC, lazy, Suspense, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom'
import {useAppSelector} from "../app/store/hooks/hooks";
import {useNavigate} from "react-router-dom";
import MainLayout from "./MainLayout";
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {checkGetMe} from "../app/store/reducers/auth/auth";

// authentication
const Login = lazy(()=>import('../pages/authentication/Login'))
const Logout = lazy(()=>import('../pages/authentication/Logout'))
const AuthLayout = lazy(()=>import('../pages/authentication/AuthLayout'))

// agent list
const AgentList = lazy(() => import('../pages/main/agent-list/AgentList'))
const AgentView = lazy(() => import('../components/agent-list/AgentView'))

// my organizations
const MyOrganizations = lazy(()=>import('../pages/main/my-organizations/MyOrganizations'))
const OrganizationView = lazy(()=>import('../components/organizations/OrganizationView'))

// settings
const Settings = lazy(()=> import('../pages/main/settings/Settings'))

//common
const ServiceChooser = lazy(()=>import('../pages/dashboard/ServiceChooser'))
const Error404 = lazy(()=>import('../pages/errors/Error404'))
const Error500 = lazy(()=>import('../pages/errors/Error500'))

const Layout:FC = () => {
    const navigate = useNavigate()

    const {isAuthorized} = useAppSelector(state => state.auth)
    const getMe = useSelector(checkGetMe)

    useEffect(() => {
        if (!isAuthorized && getMe === false)
            navigate('authentication/login')
    },[getMe])

    if(!isAuthorized && getMe === false) {
        return (
            <Suspense fallback={<Spinner animation="grow" className={'position-absolute'} style={{top: '50%', left: '50%'}} />}>
                <Routes>
                    <Route path={'authentication/'} element={<AuthLayout />}>
                        <Route path={'login'} element={<Login />}/>
                    </Route>
                </Routes>
            </Suspense>

        )
    }

    return (
        <div className={'content'}>
            <Suspense fallback={<Spinner animation="grow" className={'position-absolute'} style={{top: '50%', left: '50%'}} />}>
                <Routes>
                    <Route element={<MainLayout />}>
                        <Route path={'/'}>
                            <Route index element={<ServiceChooser />} />

                            {/* AGENT LIST */}
                            <Route path={'agent-list'} element={<AgentList />} />
                            <Route path={'agent-list/view/:id'} element={<AgentView />} />

                            {/*  ORGANIZATIONS */}
                            <Route path={'organizations'} element={<MyOrganizations />} />
                            <Route path={'organizations-view/:id'} element={<OrganizationView />} />

                            {/*  SETTINGS  */}
                            <Route path={'settings'} element={<Settings />} />
                        </Route>
                    </Route>

                    <Route path={'authentication/'} element={<AuthLayout />}>
                        <Route path={'login'} element={<Login />}/>
                        <Route path={'logout'} element={<Logout />}/>
                    </Route>

                    <Route path={'error500'} element={<Error500/>} />
                    <Route path={'error404'} element={<Error404/>} />
                    <Route path={'*'} element={<Error404 />} />
                </Routes>
            </Suspense>
        </div>
    );
};

export default Layout;
