import React, {FC} from 'react';
import NavbarDropdown from "./NavbarDropdown";
import {Nav, Navbar, Row} from "react-bootstrap";
import NavbarNavLink from "./NavbarNavLink";
import {INavbarTopDropdown} from "./types";
import  {dashboardRoutes} from "../../../routes/routes";
import {Link, useLocation} from "react-router-dom";
import {Translate} from "../../../lang/lang";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang} from "../../../app/store/reducers/main/main";

const NavbarTopDropdown:FC<INavbarTopDropdown> = ({item}) => {
    return <Nav>
        <Row>
            <NavbarNavLink title={'Home'} route={{to: 'to'}} />
        </Row>
    </Nav>
}

const NavbarTopDropdownMenus:FC = () => {

    const {pathname} = useLocation()

    const lang = useSelector(selectLang)

    const t = Translate

    return (
        <>
            {/*{dashboardRoutes.children.map(route => <NavbarDropdown to={route.to} title={route.name} />)}*/}

            {/*  <NavbarDropdown title={'Title'}>
                <NavbarTopDropdown key={'key'} item={'str'} />
            </NavbarDropdown>*/}

            {dashboardRoutes.children.map(route => <Nav.Link className={classNames({
                'text-primary':  pathname.includes(route.to),
                'fw-bold': pathname.includes(route.to)
            })} as={Link} key={route.name} to={route && route.to}>
                {t(lang,route.name)}
            </Nav.Link>)}
        </>
    );
};

export default NavbarTopDropdownMenus;
