import {RoutesType, DashboardRoutesType} from "./type";

export const dashboardRoutes: DashboardRoutesType = {
    label: 'Dashboard',
    onlyAdmin: false,
    children: [
        {
            name: 'navbar.top.agents.list',
            to: '/agent-list',
        },
        {
            name: 'navbar.top.my.organizations',
            to: '/organizations',
        },
        {
            name: 'navbar.top.settings',
            to: '/settings',
        },
    ]
}

const routes = [
    dashboardRoutes
]

export default routes
