import React, {useEffect} from 'react';
import {HashRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Layout from "./layout/Layout";
import {Toaster} from "react-hot-toast";
import {initializeAsync, loadOrganizationAccountAsync, selectIsAuthorized} from "./app/store/reducers/auth/auth";
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";


axios.defaults.withCredentials = true

function App() {

    const dispatch = useDispatch()
    const isAuthorized = useSelector(selectIsAuthorized)

    useEffect(() => {
        dispatch(initializeAsync())
    }, [])

    return (
        <HashRouter basename="/">
            <Toaster position={'top-center'} reverseOrder={true}/>
            <Layout/>
        </HashRouter>
    );
}

export default App;
