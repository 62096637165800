const EventBus = {
    on(event:any, callback:any) {
        const handler = (e:any) => callback(e.detail)
        document.addEventListener(event, handler)
        return handler
    },
    dispatch(event:any, data:any | null) {
        console.log('Event:', event, data);
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event:any, handler:any) {
        document.removeEventListener(event, handler);
    },
}

export default EventBus
