import {FC} from 'react';
import {Outlet} from 'react-router-dom'
import NavbarTop from "../components/navbar/top/NavbarTop";

const MainLayout:FC = () => {
    return (
        <div className="container-fluid">
            <NavbarTop />

            <Outlet />
        </div>
    );
};

export default MainLayout;
