import ru from "./ru.json";
import uz from "./uz.json";

export const LangOptions = [
    'ru',
    'uz'
]

const translations:any = {
    'ru': ru,
    'uz': uz
}

export const Translate = (lang:string, key:string, params = {}) => {
    const langTranslations = translations[lang] || {}
    let translation = langTranslations[key] || key
    const args:any = params || {}

    const argKeys = Object.keys(args)
    for (let i = 0; i < argKeys.length; i++) {
        const argKey = argKeys[i]
        translation = translation.replace(`{${argKey}}`, args[argKey])
    }

    return translation
}
