import classNames from "classnames";
import {Card} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectAccount} from "../../../app/store/reducers/auth/auth";

const AgentInfo = () => {


    const info = useSelector(selectAccount)


    return (
        <Card className={classNames('mx-2 w-100 d-none d-sm-block')}>
            <Card.Body className="py-1 w-100">
                <div className="d-flex justify-content-end align-items-center">
                    <p className="my-0 text-primary">
                        <span className={'me-2'}>{info.first_name ? info?.first_name : 'User'}</span>
                        <span>{info.last_name ? info?.last_name : 'Name'}</span>
                    </p>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <p className="my-0 text-warning">
                        <span>
                            +{info.phone_number ? info?.phone_number : '998XX XXX XX'}
                        </span>
                    </p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default AgentInfo;
