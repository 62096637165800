import {root} from './reducers/root'
import {configureStore} from "@reduxjs/toolkit";
import api from "./middleware/api";

const store = configureStore({
    reducer: root,
    middleware: [api]
})

export default store
