import axios from "axios";
import Api from "../type/api";

interface ActionType {
    type: string,
    payload: Api
}

const api = ({dispatch}:{dispatch:any}) => (next:any) => (action: ActionType) => {
    if(action.type !== 'apiCall') return next(action)

    next(action)

    const {url, method, data, params, onRequest, onSuccess,onFail} = action.payload

    onRequest && dispatch({type: onRequest})

    axios({
        url,
        method,
        data,
        params,
        headers: {}
    }).then((response) => {
        if(response.status === 200) {
            dispatch({type: onSuccess, payload: response.data})
        }
    }).catch((error) => {
        dispatch({type: onFail, payload: error.response.data})
    })

}

export default api
