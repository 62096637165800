import React from 'react';
import {Nav} from "react-bootstrap";
import AgentInfo from "./AgentInfo";
import ChangeLanguage from "./ChangeLanguage";
import Exit from "./Exit";
import ThemeMode from "./ThemeMode";

const NavTopRightSide = () => {
    return (
        <Nav navbar className={"navbar-nav-icons flex-row  ms-auto align-items-center"} as="ul">
            <ChangeLanguage />
            <AgentInfo />
            <ThemeMode />
            <Exit />
        </Nav>
    );
};

export default NavTopRightSide;
