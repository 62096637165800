import React, {Fragment, useContext} from 'react';
import {Nav} from "react-bootstrap";
import AppContext from "../../../context/Context";
import {BsSunFill, BsMoonFill} from 'react-icons/bs'

const ThemeMode = () => {

    const {
        config: { isDark },
        setConfig
    } = useContext<any>(AppContext);

    return (
        <Nav.Link className={'px-2 theme-control-toggle'} onClick={()=> setConfig('isDark', !isDark)}>
            <div className="theme-control-toggle-label">
                {isDark ? <BsSunFill/> : <BsMoonFill />}
            </div>
        </Nav.Link>
    );
};

export default ThemeMode;
